import React from "react";

const Hero = () => {
  return (
    <div className="pt-4 pt-md-06 pt-lg-10 ">
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-11">
            <div
              className="text-center"
              data-aos="zoom-in"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h1 className="font-size-9 mb-5">Affiliate Marketing Promo Tool</h1>
              <h2 className="font-size-7 mb-0">
                Create eye-catching content with hotspots and product links to increase click through rates.
              </h2>
            </div>
          </div>
              
        </div>
        {/* End Section Padding */}
      </div>
    </div>
  );
};

export default Hero;
