import React from "react";

const Problem = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>

      <div className="container">
      <div className="row pt-10 align-items-center justify-content-center">
        <div className="col-xl-6 col-lg-7 col-md-7 col-xs-10">
        <h6 className="font-size-9 mt-10 text-digma-dark">The Problem</h6>
        <p className="heading-default-color font-size-6 mb-4 mb-lg-9">Traditional online videos can't supply relevant information on demand​</p>
        <ul>
        <li className="mb-5"><b className="uppercase ">No Interactivity</b><br />Traditional online video content isn’t interactive and difficult to make engaging​</li>
        <li className="mb-5"><b className="uppercase ">High Drop-Off Rates​</b><br />Video drop-off rates are high due to viewers wanting immediate results​</li>
        <li className="mb-5"><b className="uppercase ">Lack of Context​</b><br />Providing enough context and detail in a short video is hard to accomplish​</li>
        <li className="mb-5"><b className="uppercase ">Complicated &amp; Expensive​</b><br />Interactive video creation is viewed as expensive and complicated​</li>
        </ul>
      </div>
      <div className="col-xl-6 col-lg-5 col-md-5 col-xs-10">
        <h6 className="font-size-9 mt-10 text-digma-dark">The Solution</h6>
        <p className="heading-default-color font-size-6 mb-4 mb-lg-9">Drive consumer engagement, whether on a desktop or mobile device, on your own website or on social media.​</p>
        <ul>
        <li className="mb-5"><b className="uppercase ">Interactive Video Overlays​</b><br />Whatever your business or audience, add depth and context to your video content with our stylish overlays ​</li>
        <li className="mb-5"><b className="uppercase ">An Easy-To-Use CMS​​</b><br />We’ve made interactive video simple. Just choose one of our beautiful templates, add your content, and you’re ready to go. You can also get creative with our fully-customisable plugins.​</li>
        <li className="mb-5"><b className="uppercase ">Unique Mobile Experience​​</b><br />Our templates will automatically adapt to all screen sizes, so all the important information your viewers need is just a tap away.​</li>
        <li className="mb-5"><b className="uppercase ">Detailed Video Analytics​​</b><br />Track the success of your interactive video with in-depth statistics on clicks, duration and usage.​</li>
        </ul>              
      </div> 
        </div>

      </div>

    </div>  
  );
};

export default Problem;


