import React from "react";
import {Link} from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Pricing from "../sections/home4/Pricing";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Hero from "../sections/affiliate/Hero";
import Content from "../sections/affiliate/Content";
import Stats from "../sections/affiliate/Stats";
import Problem from "../sections/affiliate/Problem";
import Commit from "../sections/common/Commit";
import WaveReverse from "../sections/common/WaveReverse";
import SEO from '../components/SEO';
import metaImg from '../assets/image/metaimg/meta-images.jpg'

const PricingPage = () => {

  // Example of dynamically using location prop as a crumbLabel
  // NOTE: this code will not work for every use case, and is only an example
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,
          footerStyle: "digma",
          version:"videos-affiliate",
        }}>
        <SEO
          title="Affiliate Marketing Promo Toolkit"
          description="Create eye-catching content with hotspots and product links to increase click through rates."
          image={metaImg}
        />
        <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
            <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info">Info</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>              
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/about">About</Link>
              </li>
              </ol>
           </nav>
        </div>
        <Hero />
        <WaveReverse background="#fff" color="#F7F9FC" />
        <Content className="grey pt-20 pb-0"/>
        <WaveReverse background="#F7F9FC" color="#fff" />
        <Stats className="pt-20 pb-0" />
        <WaveReverse background="#fff" color="#F7F9FC" />         
        <Problem className="grey pt-0 pb-0" />
        <WaveReverse background="#F7F9FC" color="#fff" />
        <Pricing className="pt-0 pb-0" />
        <div className='d-none d-md-block text-center pb-10 pt-5'>
        <AniLink className="font-size-7" paintDrip hex="#2196f3" to="/pricing">
            View full pricing details and plan comparison
          </AniLink>
        </div>
        <div style={{overflow:"hidden", background:"#f7f9fc"}}>
          <Commit />
        </div>
      </PageWrapper>
    </>
  );
};
export default PricingPage;
