import React from 'react';

const Content = ({ className, background, ...rest }) => {
  return (
    <div
      className={className}
      style={{ backgroundColor: background }}
      {...rest}
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {/* Right */}
          <div className="order-1 col-xl-12 col-lg-12 col-md-12 col-xs-10">
            <div className="about-content-image-group-1 mb-lg-0">
              <h3 className="font-size-8 Videos">
                How can Interactive Video and Affiliates work together?
              </h3>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
                Interactive Videos boost purchase intent &amp; increase brand
                awareness. Generate more revenue by adding affiliate links for
                products or services you recommend in your video.
              </p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
                No more links are in the comments below.{' '}
                <strong>The links are now in the video!</strong> (watch below)
              </p>
              <div className="img-1">
                <div
                  class="digma"
                  data-projectid="CBC126B9-92C5-4C01-9884-3EC4D6C2EF4B"
                ></div>
              </div>
            </div>
          </div>
          {/* Right */}
          {/* Left */}
          <div className="pt-10 order-2 col-xl-12 col-lg-12 col-md-12 col-xs-10">
            <div data-aos="fade-left" data-aos-duration={600}>
              <h3 className="Images font-size-8">
                How can Interactive Images and Affiliates work together?
              </h3>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
                Having the ability to instantly retrieve information at the
                touch of a button - or at the the tip of your fingers - has
                become part of everyday modern life.
              </p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
                Now you can add content straight to your images via the
                hotspots. <a href="https://www.neemlondon.com">Neem London</a>{' '}
                uses an interactive image on their home page to attract users to
                find out more information on their company.
              </p>
              <div
                class="digma-image"
                data-imageid="9652A941-6163-4424-AECF-87492B6059F1"
              >
                <img
                  alt="Home page collage"
                  className="w-100"
                  src="https://digmacustomers.blob.core.windows.net/30bfc815-2a7a-4782-91fb-ee941c013506/b751cbd9-393a-4853-8b48-2c4be416361a.jpg"
                />
              </div>
            </div>
          </div>
          {/* Left End */}
          {/* Left */}
          <div className="pt-10 order-3 col-xl-6 col-lg-7 col-md-7 col-xs-10">
            <div data-aos="fade-left" data-aos-duration={600}>
              <h3 className="mt-0">
                Capture data from users to repeat affiliate marketing via email.
              </h3>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
                <a
                  href="https://www.balmista.co.uk"
                  rel="noreferrer"
                  target="_blank"
                >
                  Balmista
                </a>{' '}
                use interactive images to capture competition data. This
                interactive image was used across many different platforms and
                landing pages to collect sign ups for the compeition.
              </p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
                It only takes one line of code for this to be embedded onto a
                website, blog or landing page.
              </p>
            </div>
          </div>
          {/* Left End */}
          {/* Right */}
          <div className="pt-20 order-4 col-xl-5 col-lg-5 col-md-5 col-xs-10">
            <div className="about-content-image-group-1 mb-8 mb-lg-0">
              <div className="img-1">
                <div
                  class="digma-image"
                  data-imageid="99FB0824-F0A1-4816-9E22-281E0537E6B9"
                >
                  <img
                    className="w-100"
                    alt="market competition"
                    src="https://digmacustomers.blob.core.windows.net/4fe433c9-fb0f-47d4-9f59-37f1909ec6b8/178891f3-a146-462a-8977-f53fbc9b655c.png"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Right */}
        </div>
      </div>
    </div>
  );
};

export default Content;
